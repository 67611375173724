import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { useQuery } from '~/lazy_apollo/client';
import { useSelector } from 'react-redux';

import { MenuLayout } from '~/utils/types';
import menuFeaturedSectionQuery from '../../../libs/gql/queries/menus/menuFeaturedSectionQuery.gql';

import { useScrollObserver } from '../../../utils/useScrollObserver';
import { themeShape, withTheme } from '../../../utils/withTheme';
import { classNames, withStyles } from '../../../utils/withStyles';

import Grid from '../../../shared/Grid';
import MenuSectionSkeleton from '../MenuSectionSkeleton';
import MenuSectionContentWrapper from '../MenuSectionContentWrapper';
import MenuItemCardContainer from '../MenuItemCardContainer';
import FeaturedSectionCardLayout from '../FeaturedSectionCardLayout';
import FeaturedSectionLayout from '../FeaturedSectionLayout';

import { scrollTo } from '../../../utils/dom';
import menuFeaturedSectionQueryStyles from './styles';
import { INTERSECTION_OBSERVER_SUPPORTED } from '../../shared/NavItemsV2/IntersectionObserverWrapper';

const MenuFeaturedSectionQuery = (props) => {
  const {
    currency,
    classes,
    displayExtraGroups,
    handleSectionScroll,
    includeItemLink,
    isMenuOrderingAvailable,
    locationId,
    menuId,
    menuEnabled,
    menuItemCart,
    menuOrderingUrl,
    slug,
    showAddToCartButton,
    theme,
    sectionIndex,
    setSelectedTab,
    onScrolled,
    renderMobileMenuNavV2,
    shouldPrerenderSection,
    loadedSectionIndex,
    setLoadedSectionIndex,
  } = props;
  const featuredCardRef = useRef(null);

  const menuLayout = props.menuLayout || theme.defaultMenuLayout || MenuLayout.NextLayout;
  const selectedSectionIndex = useSelector(state => state.menuItemCart.selectedSectionIndex);
  const menuSectionRef = useRef(null);
  const { scrolledTo, scrollObserverRef } = useScrollObserver();

  useEffect(() => {
    if (scrolledTo) {
      onScrolled(sectionIndex);
    }
  }, [onScrolled, sectionIndex, scrolledTo]);

  useEffect(() => {
    const node = menuSectionRef?.current;

    if (!INTERSECTION_OBSERVER_SUPPORTED || !node || !renderMobileMenuNavV2 || !setSelectedTab) return () => null;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setSelectedTab(sectionIndex);
      }
    }, { rootMargin: '-50%' });
    observer.observe(node);
    return () => observer.disconnect();
  }, [renderMobileMenuNavV2, sectionIndex, setSelectedTab]);

  useEffect(() => {
    // Scroll to specified menu section in hash link
    if (window.location?.hash) {
      const selector = window.location.hash.split('#')[1] || window.location.hash;
      const scrollInterval = setInterval(() => {
        const target = document.getElementById(selector);
        if (target) {
          if (target.getBoundingClientRect().top < window.scrollY) { // blocks repeated back scroll for multiple menu section lazy loading
            clearInterval(scrollInterval);
          } else {
            scrollTo(target);
            target.focus();
            clearInterval(scrollInterval);
          }
        }
      }, 800);
    }
  }, []);

  const isMobileCardWidth = !!(featuredCardRef.current && featuredCardRef.current.offsetWidth < 450);

  const { data, loading } = useQuery(
    menuFeaturedSectionQuery,
    {
      onCompleted: () => {
        // If the selected section content is rendered, run scroll function.
        if (selectedSectionIndex === sectionIndex) {
          handleSectionScroll();
        }
      },
      // This ensures that the section's content will load once at a time and in order
      skip: (!scrolledTo && !shouldPrerenderSection(sectionIndex)) || loadedSectionIndex + 1 < sectionIndex,
      variables: {
        menuId,
      },
    },
  );

  let columns = 1;
  if (loading || !data || !data.menuFeaturedSection) {
    return (
      <MenuSectionContentWrapper
        elementId={`section-${slug}`}
        menuLayout={menuLayout}
        menuSectionRef={menuSectionRef}
      >
        <div ref={scrollObserverRef}>
          <MenuSectionSkeleton
            menuColumnCount={1}
          />
        </div>
      </MenuSectionContentWrapper>
    );
  } else {
    // Once section's content has been rendered, update the state
    setLoadedSectionIndex(sectionIndex);
    if (selectedSectionIndex === sectionIndex) {
      // If selected section content is already rendered, run scroll function.
      handleSectionScroll();
    }
  }

  const { backgroundColor, enabledItems, name, description, layout, isAccented } = data.menuFeaturedSection;
  if (data.menuFeaturedSection) {
    if (enabledItems.length > 1 && !showAddToCartButton) {
      columns = 2;
    }
  }

  if (enabledItems.length === 0) {
    return null;
  }

  const menuLayoutSection = showAddToCartButton ? MenuLayout.NextLayout : menuLayout;

  return (
    <MenuSectionContentWrapper
      backgroundColor={backgroundColor}
      description={description}
      elementId={`section-${slug}`}
      menuLayout={menuLayout}
      menuSectionRef={menuSectionRef}
      name={name}
      scrollObserverRef={scrollObserverRef}
      isAccented={isAccented}
    >
      <Grid container spacing={0} justifyContent="center">
        <Grid container spacing={0} className={classes.containerSize}>
          <FeaturedSectionLayout layout={layout} length={enabledItems.length} menuLayout={menuLayoutSection} showAddToCartButton={showAddToCartButton}>
            {enabledItems.map((menuItem, i) => (
              <Grid key={menuItem.id} id={`featured-item-card-${i}`} item sm={12 / columns} xs={12} className={classNames([classes.itemPadding, layout === 'carousel' ? classes.carouselItem : null])}>
                <FeaturedSectionCardLayout menuLayout={menuLayoutSection}>
                  <div ref={featuredCardRef}>
                    <MenuItemCardContainer
                      currency={currency}
                      displayExtraGroups={displayExtraGroups}
                      includeItemLink={includeItemLink}
                      featuredSection
                      featuredMobileCardWidth={isMobileCardWidth}
                      isMenuOrderingAvailable={isMenuOrderingAvailable}
                      menuEnabled={menuEnabled}
                      menuItem={menuItem}
                      menuItemCart={menuItemCart}
                      menuLayout={menuLayout}
                      menuOrderingUrl={menuOrderingUrl}
                      showAddToCartButton={showAddToCartButton}
                      locationId={locationId}
                    />
                  </div>
                </FeaturedSectionCardLayout>
              </Grid>
            ))}
          </FeaturedSectionLayout>
        </Grid>
      </Grid>
    </MenuSectionContentWrapper>
  );
};

MenuFeaturedSectionQuery.defaultProps = {
  allowReservation: true,
  backgroundColor: null,
  backgroundEffect: null,
  backgroundImageUrl: null,
  disclaimer: null,
  displayExtraGroups: false,
  displayPdfType: null,
  includeItemLink: true,
  menuItemCart: null,
  menuLayout: null,
  menuOrderingUrl: null,
  showAddToCartButton: false,
};

MenuFeaturedSectionQuery.propTypes = {
  allowReservation: PropTypes.bool,
  backgroundColor: PropTypes.string,
  backgroundEffect: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  currency: PropTypes.string.isRequired,
  disclaimer: PropTypes.string,
  displayExtraGroups: PropTypes.bool,
  displayPdfType: PropTypes.string,
  includeItemLink: PropTypes.bool,
  isMenuOrderingAvailable: PropTypes.bool.isRequired,
  menuId: PropTypes.number.isRequired,
  menuItemCart: PropTypes.object,
  menuLayout: PropTypes.string,
  menuOrderingUrl: PropTypes.string,
  onScrolled: PropTypes.func.isRequired,
  renderMobileMenuNavV2: PropTypes.bool.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  shouldPrerenderSection: PropTypes.func.isRequired,
  showAddToCartButton: PropTypes.bool,
  theme: themeShape.isRequired,
};

export default compose(
  withTheme,
  withStyles(menuFeaturedSectionQueryStyles),
)(MenuFeaturedSectionQuery);
