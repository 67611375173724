import React from 'react';
import { Icon } from '@popmenu/common-ui';
import { Camera, ChevronDown } from '@popmenu/web-icons';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';

import { MenuLayout } from '~/utils/types';
import { withCurrentSession } from '../../shared/CurrentSessionProvider';
import { withRestaurant } from '../../utils/withRestaurant';
import { hexToRgba, rgbaToHex } from '../../utils/colors';
import { formatCurrency } from '../../utils/currency';
import { nl2br } from '../../utils/react';
import { reviewFromSession } from '../../utils/sessions';
import { popFromSession } from '../../utils/pops';
import { truncateString } from '../../utils/strings';
import { classNames } from '../../utils/withStyles';
import { themeShape, withTheme } from '../../utils/withTheme';
import DishExtras from './DishExtras';
import DishTags from './DishTags';
import CardPhoto from './CardPhoto';
import PopBubble from '../pops/PopBubble';
import PopsCombo from '../pops/PopsCombo';
import { AH } from '../shared/AccessibleHeading';
import ModernEngagementIcons from '../shared/ModernEngagementIcons';

const TEXT_ALIGN_MAP = {
  item_font_center: 'center',
  item_font_left: 'left',
};

class MenuItemCard extends React.PureComponent {
  backgroundColor() {
    return this.props.itemBackgroundColor || this.props.sectionBackgroundColor || this.props.menuBackgroundColor;
  }

  descriptionStyle() {
    return {
      color: this.props.theme.menuItemDescriptionFont.color,
      fontFamily: this.props.theme.menuItemDescriptionFont.family && `"${this.props.theme.menuItemDescriptionFont.family}"`,
      textAlign: TEXT_ALIGN_MAP[this.props.theme.menuItemFontAlignment],
    };
  }

  nameStyle() {
    let weight = null;
    if (this.props.theme.menuItemNameFont.weight === 'normal') {
      weight = 400;
    }
    if (this.props.theme.menuItemNameFont.weight === 'lighter') {
      weight = 100;
    }
    if (this.props.theme.menuItemNameFont.weight === 'bold') {
      weight = 900;
    }
    return {
      color: this.props.theme.menuItemNameFont.color,
      fontFamily: this.props.theme.menuItemNameFont.family && `"${this.props.theme.menuItemNameFont.family}"`,
      fontWeight: weight,
      textAlign: TEXT_ALIGN_MAP[this.props.theme.menuItemFontAlignment],
      textTransform: this.props.theme.menuItemNameFont.case,
    };
  }

  renderReviewCount() {
    if (this.props.reviewsCount > 0) {
      return (
        <div className="reviews-count">
          <span>{this.props.reviewsCount === 1 ? '1 review' : `${this.props.reviewsCount} reviews`}</span>
        </div>
      );
    }
    return null;
  }

  renderPhoto() {
    return (
      <CardPhoto
        photoUrl={this.props.featuredPhoto?.photoUrl}
        featuredTag={this.props.menuLayout === MenuLayout.SimpleTwo && this.renderFeaturedTag()}
        name={this.props.name}
        reviewCount={this.renderReviewCount()}
        showFeaturedPhoto={this.props.showFeaturedPhoto}
      />
    );
  }

  renderPhotoIcon() {
    if (this.props.featuredPhoto && !this.props.showFeaturedPhoto) {
      const photoIconColor = {
        menu_icon_fx_black: '#000',
        menu_icon_fx_none: '#8D8D8D',
        menu_icon_fx_white: '#FFF',
      };
      const style = {
        color: photoIconColor[this.props.theme.menuIconEffect],
      };
      return (
        <div
          aria-label="Item has photos"
          className="camera"
          style={style}
        >
          <Icon icon={Camera} />
        </div>
      );
    }
    return null;
  }

  renderPrice() {
    const style = {};
    if ((this.props.priceCustomText || this.props.price) && this.props.theme.showPrice) {
      if (this.props.theme.menuItemFontAlignment === 'item_font_center') {
        style.float = 'none';
        style.marginLeft = 0;
      }
      return (
        <span className="price" style={style}>
          <span>
            {this.props.price && (
              formatCurrency(this.props.price, this.props.currency, { showSymbol: this.props.theme.showCurrencySymbol })
            )}
            {this.props.priceCustomText && (
              `${this.props.price ? ` ${this.props.priceCustomText}` : this.props.priceCustomText}`
            )}
          </span>
        </span>
      );
    }
    return null;
  }

  renderPopBubble() {
    const modernLayout = this.props.theme.dishLayout === 'modern_dish_layout';
    const menuItem = {
      likedItPopsCount: this.props.likedItPopsCount,
      lovedItPopsCount: this.props.lovedItPopsCount,
      menuItemId: this.props.id,
      menuItemName: this.props.name,
      poppableId: this.props.dishId,
      poppableType: 'Dish',
      popsCount: this.props.popsCount,
      wannaTryPopsCount: this.props.wannaTryPopsCount,
    };
    if (this.props.isPoppable) {
      return (
        <div className="pm-pop-bubble">
          {!modernLayout && (
            <PopBubble {...menuItem} />
          )}
          {modernLayout && (
            <ModernEngagementIcons menuItem={menuItem} restaurant={this.props.restaurant} compact />
          )}
        </div>
      );
    }
    return null;
  }

  renderSimpleTitle() {
    if ((this.props.priceCustomText || this.props.price) && this.props.theme.showPrice) {
      return (
        <div>
          <div className="dish-title">
            {this.renderPrice()}
            <AH variant="h4" style={this.nameStyle()}>
              {this.props.name}
            </AH>
          </div>
          {this.renderPopBubble()}
          {this.renderPhotoIcon()}
        </div>
      );
    }
    return (
      <div>
        {this.renderPopBubble()}
        {this.renderPhotoIcon()}
        <div className="dish-title">
          <AH variant="h4" style={this.nameStyle()}>
            {this.props.name}
          </AH>
        </div>
      </div>
    );
  }

  renderDescription() {
    if (this.props.theme.showMenuItemDescription) {
      return (
        <div>
          {this.props.description && (
            <p className="description">
              {!this.props.featuredSection && nl2br(this.props.description)}
              {this.props.featuredSection && nl2br(truncateString(this.props.description, 55))}
            </p>
          )}
          {this.props.theme.showPrice && (
            <DishExtras
              currency={this.props.currency}
              extraGroups={this.props.extraGroups}
              extras={this.props.extras}
              sharedExtraGroups={this.props.sharedExtraGroups}
              showCurrencySymbol={this.props.theme.showCurrencySymbol}
              displayExtraGroups={this.props.displayExtraGroups}
              sizes={this.props.sizes}
              textAlign={TEXT_ALIGN_MAP[this.props.theme.menuItemFontAlignment]}
            />
          )}
        </div>
      );
    }
    return null;
  }

  renderDishTags() {
    if (this.props.theme.showMenuItemDishTags && this.props.dish.allSelectedDishTags.length > 0) {
      return (
        <DishTags
          allSelectedDishTags={this.props.dish.allSelectedDishTags}
        />
      );
    }
    return null;
  }

  renderCoverLink() {
    const style = {};
    if (this.props.theme.primaryColor) {
      style.color = this.props.theme.primaryColor;
    }
    const props = {
      'aria-label': `${this.props.name} Details`,
      className: 'pm-cover-link',
      'data-cy': 'menu_item_link',
      onClick: this.props.openModal,
      onKeyPress: this.props.openModal,
      role: 'button',
      style,
      tabIndex: '0',
    };
    let component = 'div';
    if (this.props.includeItemLink) {
      component = 'a';
      Object.assign(props, { 'data-menu-item-link': 'true', href: this.props.url });
    }
    return React.createElement(component, props, <React.Fragment>&nbsp;</React.Fragment>);
  }

  renderFeaturedTag() {
    if (this.props.featured && !this.props.featuredSection) {
      let backgroundColor = this.props.theme.primaryColor || '#000000';
      if (backgroundColor.indexOf('#') === 0) {
        backgroundColor = hexToRgba(backgroundColor, 70);
      } else if (backgroundColor.indexOf('rgb') === 0) {
        backgroundColor = hexToRgba(rgbaToHex(backgroundColor).color, 70);
      }
      return (
        <div className="featured-text" style={{ backgroundColor }}>
          <FormattedMessage id="dishes.featured" defaultMessage="Featured" />
        </div>
      );
    }
    return null;
  }

  renderContent() {
    if (this.props.menuLayout === MenuLayout.Card) {
      return (
        <div className="content">
          <div className="pm-top">
            {this.renderPhoto()}
          </div>
          <div className="pm-bottom">
            <div className="dish-description" style={this.descriptionStyle()}>
              <AH variant="h4" className="dish-title" style={this.nameStyle()}>
                {this.props.name}
              </AH>
              {this.renderDescription()}
              {this.renderDishTags()}
              {this.renderPrice()}
              {this.renderPopBubble()}
            </div>
            <button
              aria-label={`${this.props.name} Details`}
              className="a dish-toggle-link more"
              tabIndex="-1"
              type="button"
            >
              <Icon icon={ChevronDown} />
            </button>
          </div>
          {this.renderFeaturedTag()}
          {this.renderCoverLink()}
        </div>
      );
    } else if (this.props.menuLayout === MenuLayout.Simple) {
      return (
        <div className="content">
          <div className="pm-bottom">
            {this.renderPhoto()}
            <div className="dish-description" style={this.descriptionStyle()}>
              {this.renderSimpleTitle()}
              {this.renderDescription()}
              {this.renderDishTags()}
            </div>
          </div>
          {this.renderCoverLink()}
          {this.renderFeaturedTag()}
        </div>
      );
    } else if (this.props.menuLayout === MenuLayout.SimpleTwo) {
      return (
        <div className="content">
          <div className="pm-bottom">
            <div className="dish-description" style={this.descriptionStyle()}>
              <div
                className="dish-header"
                style={
                  this.props.theme.menuItemFontAlignment === 'item_font_center' ? {
                    alignItems: 'center',
                    flexDirection: 'column-reverse',
                    justifyContent: 'flex-start',
                  } : null
                }
              >
                <AH variant="h4" className="dish-title" style={this.nameStyle()}>
                  {this.props.name} {this.renderPhotoIcon()}
                </AH>
                {this.renderPrice()}
              </div>
              {this.renderDescription()}
              {this.renderDishTags()}
            </div>
            {this.renderPhoto()}
          </div>
          <PopsCombo
            backgroundColor={this.backgroundColor()}
            dishId={this.props.dishId}
            likedItPopsCount={this.props.likedItPopsCount}
            lovedItPopsCount={this.props.lovedItPopsCount}
            isPoppable={this.props.isPoppable}
            onClick={this.props.openModal}
            popsCount={this.props.popsCount}
            reviewsCount={this.props.reviewsCount}
            wannaTryPopsCount={this.props.wannaTryPopsCount}
            userPop={this.props.userPop}
            userReview={this.props.userReview}
          />
          {this.renderCoverLink()}
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div
        className={classNames(
          'pm-dish-card',
          this.props.featured ? 'featured' : null,
          this.props.featuredPhoto && this.props.showFeaturedPhoto ? 'photo' : null,
          (this.props.description || this.props.extras.length || this.props.sizes.length) && this.props.theme.showMenuItemDescription ? 'has-description' : null,
          this.props.featuredSection && 'featured-item-dish-card',
        )}
        style={{
          backgroundColor: this.props.itemBackgroundColor,
          cursor: 'pointer',
        }}
      >
        {this.renderContent()}
      </div>
    );
  }
}

MenuItemCard.defaultProps = {
  description: null,
  dish: {
    allSelectedDishTags: [],
  },
  displayExtraGroups: false,
  featuredPhoto: null,
  featuredSection: false,
  itemBackgroundColor: null,
  menuBackgroundColor: null,
  price: null,
  priceCustomText: null,
  sectionBackgroundColor: null,
  url: null,
  userPop: null,
  userReview: null,
};

MenuItemCard.propTypes = {
  currency: PropTypes.string.isRequired,
  description: PropTypes.string,
  dish: PropTypes.shape({
    allSelectedDishTags: PropTypes.array,
  }),
  dishId: PropTypes.number.isRequired,
  displayExtraGroups: PropTypes.bool,
  extras: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
  })).isRequired,
  featured: PropTypes.bool.isRequired,
  featuredPhoto: PropTypes.shape({
    id: PropTypes.number,
    photoUrl: PropTypes.string,
  }),
  featuredSection: PropTypes.bool,
  id: PropTypes.number.isRequired,
  includeItemLink: PropTypes.bool.isRequired,
  isPoppable: PropTypes.bool.isRequired,
  itemBackgroundColor: PropTypes.string,
  likedItPopsCount: PropTypes.number.isRequired,
  lovedItPopsCount: PropTypes.number.isRequired,
  menuBackgroundColor: PropTypes.string,
  menuLayout: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  popsCount: PropTypes.number.isRequired,
  price: PropTypes.number,
  priceCustomText: PropTypes.string,
  restaurantId: PropTypes.number.isRequired,
  reviewsCount: PropTypes.number.isRequired,
  sectionBackgroundColor: PropTypes.string,
  showFeaturedPhoto: PropTypes.bool.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
  })).isRequired,
  slug: PropTypes.string.isRequired,
  theme: themeShape.isRequired,
  url: PropTypes.string,
  userPop: PropTypes.shape({
    popType: PropTypes.string,
  }),
  userReview: PropTypes.shape({
    content: PropTypes.string,
  }),
  wannaTryPopsCount: PropTypes.number.isRequired,
};

export default compose(
  withCurrentSession,
  withTheme,
  withRestaurant,
  mapProps(({ currentSession, ...props }) => ({
    ...props,
    userPop: popFromSession(currentSession, props.dishId),
    userReview: reviewFromSession(currentSession, props.dishId),
  })),
)(MenuItemCard);
