import React from 'react';
import { connect, type ConnectedProps } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { FormattedMessage } from 'react-intl';

import { nl2br } from '../../utils/react';
import { classNames } from '../../utils/withStyles';
import { openVipV2Modal } from '../../shared/ModalActions';
import { withTheme, type WithThemeProps } from '../../utils/withTheme';
import { withCurrentSession, type WithCurrentSessionProps } from '../../shared/CurrentSessionProvider';
import { withRestaurant, type WithRestaurantProps } from '../../utils/withRestaurant';
import { AH, AHLevelProvider } from '../shared/AccessibleHeading';

const connector = connect(() => ({}), { openVipV2Modal });
type ReduxProps = ConnectedProps<typeof connector>;

type MenuSectionContentWrapperProps = {
  backgroundColor?: string;
  description?: string;
  elementId?: string;
  isAccented?: boolean;
  isHeaderEnabled?: boolean;
  isSecret?: boolean;
  menuLayout: string;
  menuSectionRef?: React.RefObject<HTMLDivElement>;
  name?: string;
  scrollObserverRef?: React.RefObject<HTMLDivElement>;
};

type MenuSectionContentWrapperMappedProps = {
  isSecret: boolean;
  signUpHeadingCta: string;
};

type InnerMenuSectionContentWrapperProps =
  MenuSectionContentWrapperProps &
  WithThemeProps &
  ReduxProps &
  MenuSectionContentWrapperMappedProps;

class MenuSectionContentWrapper extends React.PureComponent<InnerMenuSectionContentWrapperProps> {
  static defaultProps = {
    backgroundColor: undefined,
    description: undefined,
    elementId: undefined,
    isAccented: false,
    isHeaderEnabled: true,
    menuSectionRef: undefined,
    name: undefined,
    scrollObserverRef: undefined,
  };

  renderHeader() {
    const style: {
      color?: string,
      fontFamily?: string,
      borderBottomColor?: string,
    } = {};
    if (this.props.theme.menuHeaderFont) {
      if (this.props.theme.menuHeaderFont.color) {
        style.color = this.props.theme.menuHeaderFont.color;
        style.borderBottomColor = this.props.theme.menuHeaderFont.color;
      }
      if (this.props.theme.menuHeaderFont.family) {
        style.fontFamily = `"${this.props.theme.menuHeaderFont.family}"`;
      }
    }
    return (
      <AH variant="h3" style={style} className="pm-menu-section-header">{this.props.name}</AH>
    );
  }

  renderDescription() {
    if (this.props.description) {
      const menuHeaderFontColor = this.props.theme.menuHeaderFont?.color;
      const style = menuHeaderFontColor ? { color: menuHeaderFontColor } : {};
      return (
        <p className="section-description" style={style}>
          {nl2br(this.props.description)}
        </p>
      );
    }
    return null;
  }

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  renderSecretMessage() {
    if (this.props.isSecret) {
      return (
        <div onClick={() => this.props.openVipV2Modal()}>
          <div className="pm-secret-bg" />
          <span className="pm-secret-message">
            <button className="a" type="button">
              <FormattedMessage
                id="menus.content_wrapper.view_secret_menu"
                defaultMessage="{signUpHeadingCta}{br}to view our secret menu!"
                values={{
                  br: <br />,
                  signUpHeadingCta: this.props.signUpHeadingCta,
                }}
              />
            </button>
          </span>
        </div>
      );
    }
    return null;
  }
  /* eslint-enable jsx-a11y/click-events-have-key-events */
  /* eslint-enable jsx-a11y/no-static-element-interactions */

  render() {
    const shouldRenderHeader = !!this.props.name && this.props.isHeaderEnabled;
    return (
      <div className={`pm-menu-wrap pm-theme-${this.props.menuLayout}`} ref={this.props.scrollObserverRef}>
        <div
          className={classNames(
            'pm-menu-section',
            this.props.isSecret ? 'pm-secret' : null,
            this.props.isAccented ? 'pm-accented' : null,
            this.props.description ? 'has-description' : null,
          )}
          id={this.props.elementId}
          style={{
            backgroundColor: this.props.backgroundColor,
          }}
          tabIndex={-1}
        >
          <div
            aria-hidden={this.props.isSecret ? 'true' : 'false'}
            className={this.props.isSecret ? 'pm-secret-content' : undefined}
            ref={this.props.menuSectionRef}
          >
            {shouldRenderHeader && this.renderHeader()}
            <AHLevelProvider>
              {this.renderDescription()}
              {this.props.children}
            </AHLevelProvider>
          </div>
          {this.renderSecretMessage()}
        </div>
      </div>
    );
  }
}

export default compose<InnerMenuSectionContentWrapperProps, MenuSectionContentWrapperMappedProps>(
  withCurrentSession,
  withTheme,
  withRestaurant,
  mapProps<
    MenuSectionContentWrapperProps & WithThemeProps & MenuSectionContentWrapperMappedProps,
    MenuSectionContentWrapperProps & WithCurrentSessionProps & WithThemeProps & WithRestaurantProps
  >(({ restaurant, currentSession, ...props }) => ({
    ...props,
    isSecret: !!props.isSecret && !currentSession.user,
    signUpHeadingCta: restaurant.signUpHeadingCta,
  })),
  connect(() => ({}), { openVipV2Modal }),
)(MenuSectionContentWrapper);
